<template>
  <div class="right">
    <div class="title titleborder">
      {{ form.title }}
      <div class="rightreleaseTime">发布时间：{{ form.createTime }}</div>
    </div>
    <!-- 视频 -->
    <div class="video1">
      <video width="800" height="450" :src="'/com/file/getVideo?filePath=' + form.videoFile" controls="controls" :poster="'/com/file/fileDownLoad?filePath=' + form.videoImage"></video>
    </div>
  </div>
</template>

<script>
import { getVideoDetail } from "@/api/lectureHall.js";

export default {
  data() {
    return {
      form: {},
    };
  },
  mounted() {
    let id = this.$route.query.id;
    this.getvideo(id);
  },
  methods: {
    getvideo(id) {
      getVideoDetail({ id: id,type:2 }).then((res) => {
        if (res.code == 1) {
          this.form = res.data || {};
        }
      });
    },
  },
};
</script>

<style scoped lang="less">
.right {
  width: 960px;
  padding-left: 30px;
  .title {
    width: 960px;
    // height: 106px;
    font-size: 26px;
    color: #222222;
    // line-height: 106px;
    padding-bottom: 30px;
    text-align: center;
  }
  .titleborder {
    position: relative;
    border-bottom: 1px solid #e6e6e6;
    margin-bottom: 30px;
  }
  .rightreleaseTime {
    position: absolute;
    bottom: 10px;
    right: 0px;
    font-size: 14px;
    height: 20px;
    color: #999999;
  }
}
.video1{
    width: 800px;
    margin: 0 auto;
}
</style>